import cloneDeep from "lodash.clonedeep";
import set from "lodash.set";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { ACTIONS } from "../../constants";
import { currencyFormatter } from "../../formatters";
import ajv from "../../helpers/ajv";
import { resolveSchema } from "../../helpers/resolveSchema";
import { maybeAddRemoveTripra, parseSubmission } from "../../projects/mel/helpers";
import * as api from "../api";
import Banner from "../components/Banner";
import Button from "../components/Button";
import Form from "../components/Form";
import InputCheckbox from "../components/InputCheckbox";
import Modal from "../components/Modal";
import Table from "../components/Table";
import mapChildren from "../helpers/mapChildren";
import { useAuth, useContractQuery, useQuoteQuery } from "../hooks";

const { Body, Data, Row } = Table;

const BindQuoteModalForm = ({ callback, handleClose, quoteData, schemaData, contractData }) => {
  const parsedSubmission = parseSubmission(quoteData.submission);
  const { checkPermissions } = useAuth();
  const { contractId, endorsementId, isUpdating, productRef, quoteId, updateQuote } = useQuoteQuery();
  const { mutateAsync: checkReferrals, ...checkReferralsQuery } = useMutation(api.checkReferrals);
  const checkReferralsQueryData = checkReferralsQuery?.data?.data?.data || [];
  const willBeReferred = checkReferralsQueryData.length > 0;
  const [formValues, setFormValues] = useState({
    broker_information: contractData.submission.broker_information,
    quote: quoteData.submission.quote,
  });

  const [isBindConfirmed, setBindConfirmed] = useState(false);
  const clonedSchema = cloneDeep(schemaData);
  const resolvedSchema = resolveSchema(clonedSchema, formValues);
  const clonedValues = cloneDeep(formValues);
  const validate = ajv.compile(schemaData);
  const isValid = validate(clonedValues);

  const { grossPremium, tripraAmount } = maybeAddRemoveTripra(
    parsedSubmission.tripra,
    formValues.quote.tripra === "Yes",
    quoteData.rates.grossPremium,
    quoteData.rates.tripraAmount,
  );

  const nextSubmission = {
    ...contractData.submission,
    broker_information: {
      ...contractData.submission.broker_information,
      ...formValues.broker_information,
    },
    quote: {
      ...contractData.submission.quote,
      ...formValues.quote,
    },
  };

  const handleChange = (...args) => {
    const clonedFormValues = cloneDeep(formValues);

    if (args.length === 1) {
      const [event] = args;

      set(clonedFormValues, event.target.name, event.target.value);
    }

    if (args.length === 2) {
      const [value, name] = args;

      set(clonedFormValues, name, value);
    }

    setFormValues(clonedFormValues);
  };

  const handleSubmit = async () => {
    const res = await updateQuote({
      contractId,
      data: { type: ACTIONS.BIND_QUOTE, payload: { submission: nextSubmission } },
      endorsementId,
      productRef,
      quoteId,
    });

    handleClose();
    callback(res?.data?.data);
  };

  useEffect(() => {
    checkReferrals({ data: { nextSubmission, prevSubmission: contractData.submission } });
  }, [JSON.stringify(formValues)]);

  useEffect(() => {
    setBindConfirmed(false);
  }, [willBeReferred]);

  return (
    <div className="p-6">
      <Table className="mb-8">
        <Body>
          <Row className="border-b border-gray-500 last:border-0">
            <Data>Limit required</Data>
            <Data>{currencyFormatter(parsedSubmission.limit)}</Data>
          </Row>

          <Row className="border-b border-gray-500 last:border-0">
            <Data>Deductible</Data>
            <Data>{currencyFormatter(parsedSubmission.deductible)}</Data>
          </Row>

          <Row className="border-b border-gray-500 last:border-0">
            <Data>TRIPRA Premium</Data>
            <Data>{currencyFormatter({ amount: tripraAmount })}</Data>
          </Row>

          <Row className="border-b border-gray-500 last:border-0">
            <Data className="font-bold">Gross premium</Data>
            <Data className="font-bold">{currencyFormatter({ amount: grossPremium })}</Data>
          </Row>
        </Body>
      </Table>

      <Form onSubmit={handleSubmit}>
        {mapChildren({
          formValues,
          onChange: handleChange,
          parentKey: "",
          parentSchema: resolvedSchema,
          setFormValues,
          validationErrors: validate.errors,
        })}

        {willBeReferred && (
          <Banner className="mb-8" color="red" headingText="This quote will be referred." key={willBeReferred}>
            <div className="mb-4">
              {checkPermissions(ACTIONS.VIEW_REFERRAL_REASONS) &&
                (checkReferralsQueryData || []).map((reason) => <p key={reason}>{reason}</p>)}
            </div>

            <InputCheckbox
              labelText="I’d like to refer this quote"
              name="confirm_bind"
              onChange={() => setBindConfirmed(!isBindConfirmed)}
              isChecked={isBindConfirmed}
            />
          </Banner>
        )}

        {isValid && !willBeReferred && (
          <Banner
            key={formValues.quote.inceptionDate}
            className="mb-8 shadow-none  bg-gray-50"
            color="orange"
            headingText="Are you sure you want to bind this quote? This cannot be undone."
          >
            <InputCheckbox
              labelText="I understand"
              name="confirm_bind"
              onChange={() => setBindConfirmed(!isBindConfirmed)}
              isChecked={isBindConfirmed}
            />
          </Banner>
        )}

        <div className="flex">
          <Button
            className="mr-4"
            isDisabled={!isValid || !isBindConfirmed || isUpdating || checkReferralsQuery.isLoading}
            kind="primary"
            type="submit"
          >
            Submit
          </Button>

          <Button onClick={handleClose}>Cancel</Button>
        </div>
      </Form>
    </div>
  );
};

const BindQuoteModal_mel = ({ handleClose, callback }) => {
  const { contractData, isLoading: isContractLoading } = useContractQuery();
  const { quoteData, isLoading: isQuoteLoading, schemaData } = useQuoteQuery();
  const isLoading = isContractLoading || isQuoteLoading;

  return (
    <Modal handleClose={handleClose} headingText={!isLoading && "Bind quote"} isLoading={isLoading} clearParamsOnClose>
      <BindQuoteModalForm
        callback={callback}
        handleClose={handleClose}
        quoteData={quoteData}
        contractData={contractData}
        schemaData={{ ...schemaData.properties.BindQuoteForm, definitions: schemaData.definitions }}
      />
    </Modal>
  );
};

BindQuoteModal_mel.propTypes = {
  handleClose: PropTypes.func.isRequired,
};

export default BindQuoteModal_mel;
