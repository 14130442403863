import React from "react";
import { ACTIONS } from "../../../constants";
import Accordion from "../../components/Accordion";
import Icon from "../../components/Icon";

const AccordionPanelBespoke = ({
  prevBespokeEndos,
  currBespokeEndos,
  isEndorsement,
  handleActions,
  changesOnly,
  ...props
}) => {
  return (
    <Accordion.Panel key="bespoke_endorsements" headingText="Bespoke endorsements" {...props}>
      {currBespokeEndos.map((item, index) => {
        const prevEndo = prevBespokeEndos.find((b) => b.id === item.id);
        const isLast = index === currBespokeEndos.length - 1;
        const isEqual = JSON.stringify(item) === JSON.stringify(prevEndo);
        const isAdded = !prevEndo;
        const isChanged = prevEndo && !isEqual;

        if (changesOnly && !isLast) {
          return false;
        }

        if (!isEndorsement || !isLast) {
          return (
            <div key={item.id} className="p-4 border-b border-gray-300 flex justify-between">
              <div>
                <p className="pb-4 text-sm text-gray-800">{item.ref}</p>
                <p>{item.content}</p>
              </div>
            </div>
          );
        }

        return (
          <div key={item.id} className="p-4 border-b border-gray-300 flex justify-between">
            {isChanged && (
              <div className="p-2 inline-flex bg-red-100 rounded line-through">
                <div>
                  <p className="pb-4 text-sm text-gray-800">{prevEndo.ref}</p>
                  <p>{prevEndo.content}</p>
                </div>
              </div>
            )}

            {(isAdded || isChanged) && (
              <div className="p-2 inline-flex bg-green-100 rounded">
                <div>
                  <p className="pb-4 text-sm text-gray-800">{item.ref}</p>
                  <p>{item.content}</p>
                </div>
              </div>
            )}

            <div>
              <Icon
                name="edit"
                className="cursor-pointer w-6 fill-current text-gray-700"
                onClick={() => handleActions({ type: ACTIONS.EDIT_BESPOKE, bespoke: item })}
              />
            </div>
          </div>
        );
      })}
    </Accordion.Panel>
  );
};

export default AccordionPanelBespoke;
