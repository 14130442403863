import { Auth } from "@aws-amplify/auth";
import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { ROUTES } from "../../../constants";
import { queryClient } from "../../helpers/queryClient";

class Logout extends Component {
  componentDidMount() {
    setTimeout(() => {
      window.localStorage.removeItem("user");
      queryClient.clear();

      Auth.signOut();
    }, 1000);
  }

  render() {
    return <Redirect to={ROUTES.LOGIN} />;
  }
}

export default Logout;
